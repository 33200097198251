import { Field } from 'SourceUtil/Query';

/**
 * Ordered Summary Query
 * @class OrderedSummaryQuery
 * @namespace Tigerone/Query/RedirectStore/Query */
export class RedirectStoreQuery {
    getLanguageSwitchUrl(input) {
        return new Field('getLanguageSwitchUrl')
            .addArgument('input', 'langSwtichInput!', input)
            .addField('url');
    }

    getCustomUrlRewrites(url_key) {
        return new Field('geturlrewrites')
            .addArgument('url_key', 'String', url_key)
            .addField('code')
            .addField('url_key')
            .addField('entity_type')
            .addField('path');
    }
}

export default new RedirectStoreQuery();
